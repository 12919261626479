import { useEffect, useState } from 'react';
import {Card, Form} from 'react-bootstrap';
import axios from '../../../../services/axios';
import { decode as base64_decode } from 'base-64';

const OpcoesCte = ({rbTomador, setRbTomador, showOptConsulta, arrStrg, empselecionada, setRbPeriodoCte, rbNsuCte, setRbNsuCte, txtNsuValueCte, setTxtNsuValueCte, handleKeyUpNSUCte}) => {
  const [saldoCte, setSaldoCte] = useState(-1);
  //const storageGeral = JSON.parse(base64_decode());
  const arrStrgGeral = JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`)));
  const planoAntigo = arrStrgGeral[16];
  useEffect(() => {
    const fetchSaldo = async () => {
      const response = await axios.get('/api/initData/', {dest: 'getSaldoCte'});
      const data_ = await response.data;
     
      setSaldoCte(data_);
    };

    fetchSaldo();
  }, []);

  return (
    <>
      <Card className='shadow-none border mt-3 mb-1'>
        <Card.Body className='pt-2 pb-2'>
          {planoAntigo === 'f' ? <strong>Disponivel na faixa: </strong> :<strong>Saldo disponível: </strong>}
          {saldoCte > -1 ? saldoCte : <span className='fa fa-spin fa-spinner'></span>}
        </Card.Body>
      </Card>
      <Card className='shadow-none border mt-3 mb-1'>
        <Card.Body className='pt-2'>
          <strong>Participação no CT-e</strong>
          <Form.Group className='mt-2 mb-0'>
            <Form.Check
              inline
              custom
              checked={rbTomador === '0'}
              value={0}
              className='rb-w w-100'
              type='radio'
              onChange={(e) => setRbTomador(e.target.value)}
              label='Apenas Tomador dos documentos'
              name='radioTomador'
              id='radioTomadorSim'
            />
            <Form.Check
              inline
              custom
              checked={rbTomador === '1'}
              value={1}
              className='rb-w w-100'
              type='radio'
              onChange={(e) => setRbTomador(e.target.value)}
              label='Todas as participações'
              name='radioTomador'
              id='radioTomadorNao'
            />
          </Form.Group>
        </Card.Body>
      </Card>
      {showOptConsulta === true && (
        <>
          {arrStrg['possuiNotas'] === false && (
            <Card className='border mb-1'>
              <Card.Body className='pt-2 pb-2'>
                <strong>Período da consulta</strong>

                <Form.Group className='mt-2 mb-0'>
                  <Form.Check
                    inline
                    custom
                    defaultChecked={true}
                    value={90}
                    className='rb-w w-100'
                    style={{zIndex: 0}}
                    type='radio'
                    onChange={(e) => setRbPeriodoCte(e.target.value)}
                    label='Últimos 90 dias'
                    name='radioPeriodo'
                    id='radioPeriodo1'
                  />
                  <Form.Check
                    inline
                    custom
                    value={60}
                    className='rb-w w-100'
                    style={{zIndex: 0}}
                    type='radio'
                    onChange={(e) => setRbPeriodoCte(e.target.value)}
                    label='Últimos 60 dias'
                    name='radioPeriodo'
                    id='radioPeriodo2'
                  />
                  <Form.Check
                    inline
                    custom
                    value={30}
                    className='rb-w w-100'
                    style={{zIndex: 0}}
                    type='radio'
                    onChange={(e) => setRbPeriodoCte(e.target.value)}
                    label='Últimos 30 dias'
                    name='radioPeriodo'
                    id='radioPeriodo3'
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          )}
          {arrStrg['possuiNotas'] === true && (
            <Card className='border mb-2'>
              <Card.Body className='pt-2'>
                <strong>NSU</strong>

                <Form.Group className='mt-2 mb-0'>
                  <Form.Check
                    inline
                    custom
                    defaultChecked={true}
                    value={0}
                    className='rb-w w-100'
                    style={{zIndex: 0}}
                    type='radio'
                    onChange={(e) => setRbNsuCte(e.target.value)}
                    label={`Último NSU${empselecionada.maior_nsu_cte !== null ? ': ' + empselecionada.maior_nsu_cte : ''}  (recomendado)`}
                    name='radioNsuCte'
                    id='radioNSUCte1'
                  />
                  <Form.Check
                    inline
                    custom
                    value={1}
                    className='rb-w w-100'
                    style={{zIndex: 0}}
                    type='radio'
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setTimeout(() => {
                          const txt = document.getElementById('txtNsuCte');
                          txt.focus();
                        }, 300);
                      }
                      setRbNsuCte(e.target.value);
                    }}
                    label='A partir do NSU'
                    name='radioNsuCte'
                    id='radioNSUCte2'
                  />
                  {rbNsuCte === '1' && (
                    <Form.Control
                      type='text'
                      placeholder='Digite o NSU'
                      min={10}
                      onKeyUp={handleKeyUpNSUCte}
                      value={txtNsuValueCte}
                      id='txtNsuCte'
                      onChange={(e) => setTxtNsuValueCte(e.target.value)}
                      className='mt-3 col-6'
                      style={{zIndex: 0}}
                    />
                  )}
                </Form.Group>
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default OpcoesCte;
