export const BASENAME = '';
export const BASE_URL = '';
export const BASE_TITLE = ' | Espião Cloud';

export const ENDERECO_API = 'https://app.espiaonfe.com.br/';

// export const ENDERECO_API = 'http://192.168.15.11/';

export const WSCONNECT = false;

export const CONFIG = {
  layout: 'vertical', // vertical, horizontal
  subLayout: '', // horizontal-2
  collapseMenu: false, // mini-menu
  layoutType: 'menu-light', // menu-dark, menu-light, dark
  headerBackColor: 'header-blue', // header-blue, header-red, header-purple, header-info, header-green header-dark
  rtlLayout: false,
  navFixedLayout: true,
  headerFixedLayout: true,
  boxLayout: false,
  imgPerfil: 0,
  nomePerfil: 'User',
  menuUsuario: [],
  ws: null,
  sendMessage: '',
  changedDataUser: true,
  updateDataIni: false,
  primeiroAcesso: true,
  possuiEmpresas: false,
  possuiNotas: false,
  espionarSefaz: false,
  tipoConsulta: '-1',
  tipoTeste: 0,
  seqPesqNfe: -1,
  seqPesqCte: 0,
  seqPesqNfse: 0,

  jwt: {
    //secret key gerada random
    secret: `eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY3NDc4NTk2OCwiaWF0IjoxNjc0Nzg1OTY4fQ.CcEJW1_n9QNoD6AB6ekI0uV-m07iZDjDgLa57Bzpmsg`,
    timeout: '1 days',
  },
};
